/* Explore Kit Section start */
.typeOfModular{margin:0 auto 24px;display: flex; gap: 10px;max-width: 1170px;}
.typeOfModular button{background-color: #fff; box-shadow: 0 2px 5px 0 rgba(166, 166, 166, 0.25);width: 40px;height: 40px;cursor: pointer;border-radius: 5px;}
.typeOfModular button.leftRght{transform: rotate(180deg);}
.typeOfModular ul{overflow: auto;width: 100%;white-space: nowrap;text-align: center; scroll-behavior: smooth;}
.toptrandHead h2{text-align: center;}
.toptrandHead p{text-align: center;}
.typeOfModular ul::-webkit-scrollbar {height: 0;}
.typeOfModular li{margin-left: 16px;padding: 0px 16px; color: #666; display: inline-block;border-radius: 5px;line-height: 38px;cursor: pointer;font-size: 14px; background: #eee;}
.typeOfModular li:first-child{margin: 0;}
.typeOfModular li.active{background-color: #eb5976;color: #fff; border-radius: 5px;}
.exploreKitmian{background: #fff4f6; border-radius: 5px; padding: 24px 64px;}
.exploreKitmian ul{display: flex; flex-wrap: wrap; gap: 16px; width: 100%; justify-content: center;}
.exploreKitmian ul li{padding: 0px 10px; display: inline-block;border-radius: 5px;line-height: 35px;cursor: pointer; background: #fff;}
.exploreKittoles{display: flex; gap: 10px; justify-content: center; align-items: center;}
.exploreKittoles p{ color: #794e57; font-size: 14px;}
.suBtext {color: #fff;}
.suBtext > a {color: #eb5976 !important;}

.desginlaberyCardsclick{position: relative;padding: 24px;background: #272C59; border: 1px solid #eb5976; border-radius: 10px;}
.desginlaberyCardsclick .disdinlibryimg{position: absolute; bottom: 0; right: 0;}
.desginlaberyCardsclick .viewAllBtn{padding: 6px 17px;background: #eb5976; border-radius: 5px; font-size: 12px; color: #fff;cursor: pointer;}
.containerFluid{max-width: 1350px;}


@media screen and (max-width:540px){
	.typeOfModular li {padding: 0px 12px; font-size: 10px;}
	.exploreKitmian{padding: 20px;}
	.suBtext{width: 240px;}
	.desginlaberyCardsclick .viewAllBtn{position: relative;z-index: 1;}
}
/* Explore Kit Section End */
.mainBx{position: relative;}
.topTrandsMianbox{display: grid;grid-template-columns: repeat(4,1fr);gap: 24px;}
			.topTrandstext{padding: 16px 12px;position: absolute;bottom: 0;left: 0;color: #fff;z-index: 1;width: 100%;}
			.topTrandstext p{line-height: 18px; font-size: 14px; height: 100%;color: #fff;}
			.topTrandstliles{border-radius: 10px; position: relative;overflow: hidden;max-height: 240px;cursor: pointer;}
			.topTrandstliles:after {content: "";background-image: linear-gradient(0deg,#000,rgba(0,0,0,.05) 30%);position: absolute;top: 0;bottom: 0;left: 0;right: 0;}
			.topTrandstliles figure{display: flex;width: 100%;height: 100%;}
			.topTrandstliles figure img {border-radius: 5px 5px 0px 0px;width: 100%;transition: 0.3s all;}
			.topTrandstliles:hover figure img{transform: scale(1.1);}
			.topTrandsheartfevicon{position: absolute; background: #fff; opacity: 70%; padding: 6px 5px; border-radius: 5px; top: 16px; right: 16px; display: flex;align-items: center; justify-content: center;}
			.topTrandsheartfevicon.active svg path{fill: #eb5976;}
			.topTrendBx{display: flex;}
			@media screen and (max-width:768px){
				.topTrandsMianbox{grid-template-columns: repeat(3,1fr);}
				.toptrandHead h2{text-align: center;}
				.toptrandHead p{text-align: center;}
			}
			@media screen and (max-width:540px){
				.toptrandHead h2{font-size: 20px;font-weight: 500; margin-bottom: 12px;}
				.toptrandHead p{font-size: 14px; margin-bottom: 24px;}
				.topTrandsMianbox{display: flex; gap: 16px; overflow: auto;position: relative;scroll-behavior: smooth;}
				.topTrandstliles{width: 100%; min-width: 275px;}
				.topTrandstliles figure img{width: 100%;}

           }
/* Top Trands sec End */


.leftArrow{color: #000;width: 40px;height: 40px;  background-color: rgba(248, 248, 248, 0.7);border-radius: 5px;display: flex;align-items: center;justify-content: center;font-size: 20px;cursor: pointer;transition: 0.3s;}
.rightArrow{color: #000;width: 40px;height: 40px;  background-color: rgba(248, 248, 248, 0.7);border-radius: 5px;display: flex;align-items: center;justify-content: center;font-size: 20px;cursor: pointer;transition: 0.3s;}
.leftArrow:hover{background-color: rgba(225, 225, 225, 1);transition: 0.3s;} 
.rightArrow:hover{background-color: rgba(225, 225, 225, 1);transition: 0.3s;}